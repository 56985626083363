import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

function dark() {
    document.querySelector("body").classList.toggle("dark-mode")
}
const Navbar = () => {

    return (
        <header className="page-header">
            <nav>
                <Link to="/" className="logo">
                    <img src={ logo } alt="up to production Logo" />
                </Link>
                <ul className="links">
                    <li>
                        <Link to="/">
                            home
                        </Link>
                    </li>
                    <li>
                        <Link to="about">
                            about
                        </Link>
                    </li>
                    <li>
                        <Link to="projects">
                            projects
                        </Link>
                    </li>
                </ul>
                <button className="darkmode-btn" onClick={ dark }>
                    <i class="fa-regular fa-moon"></i>
                </button>
            </nav>
        </header >
    )
}

export default Navbar;