import Navbar from "./components/navbar";
import Home from "./Home";
import "./styles/App.css"
import { Routes, Route } from "react-router-dom";
import About from "./About";
import Projects from "./projects";
import Footer from "./components/footer";

function App() {
    return (
        <div className="page-container">


            <div className="not-footer">
                <Navbar />


                <main className="page-body">

                    <Routes>
                        <Route path="/" element={ <Home /> } />
                        <Route path="/about" element={ <About /> } />
                        <Route path="/projects" element={ <Projects /> } />
                    </Routes>
                </main>
            </div>

            <Footer />
        </div>
    );
}

export default App;