const Collabs = ({ collabs }) => {
    return (
        <section className="collaborations">
 
            <div className="section-title">
                <h2>
                    our <span> clients </span>
                </h2>
            </div>

            <div className="collabs-container">
                { collabs.map((collab) => (
                    <a href={ collab.link } className="collab">
                        <div className="collab-image">
                            <img src={ collab.img } alt={ collab.name } />
                        </div>

                        <div className="collab-name">
                            <h2>
                                { collab.name }
                            </h2>
                        </div>
                    </a>
                )) }
            </div>
        </section>
    );
}

export default Collabs;