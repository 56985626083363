const Banner = () => {
    return (
        <>
            <section className="banner">
                <div className="banner-container">
                    <div className="layer">
                        <h2>
                        </h2>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Banner;