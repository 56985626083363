import Services from "./components/Services";
import Team from "./components/Team";
import useFetch from "./Hooks/useFetch";

const About = () => {
    const { data: team, error, isPending } = useFetch("team");
    const { data: services } = useFetch("services");

    return (
        <>

            <section className="brief">
                <div className="section-title">
                    <h2>
                        who <span> we are </span>
                    </h2>
                </div>

                <p className="text">
                    We are Up 2 Production, We seek helping Arabs (streamers - content creators) at the lowest prices with the best quality.
                </p>
            </section>

            <div className="ribbon"></div>

            { error && <div>{ error }</div> }

            { isPending && <div>Loading...</div> }

            { services && <Services services={ services } type="list" /> }
            <div className="ribbon"></div>

            { team && <Team team={ team } /> }
            <div className="ribbon"></div>

        </>
    )
}

export default About;