import { Link } from "react-router-dom";
import Soon from "./soon";

const ProjectsView = ({ projects, button }) => {
    return (
        <section className="projects">

            <div className="section-title">
                <h2>
                    see our <span> projects </span>
                </h2>
            </div>

            <div className="projects-container">

                { projects && projects.map((project) => (
                    <a href={ project.link } key={ project.id } className="project">
                        <div className="project-image">
                            <img src={ project.image } alt={ project.name } />
                        </div>

                        <div className="project-name">
                            <h2>
                                { project.name }
                            </h2>
                        </div>

                        <div className="project-description">
                            <p>
                                { project.description }
                            </p>
                        </div>
                    </a>
                )) }

                <Soon />

            </div>
            {
                projects &&
                (button === true ?
                    <Link className="btn" to={ '/projects' }>
                        view all projects
                    </Link> : <></>)
            }

        </section >
    );
}

export default ProjectsView;