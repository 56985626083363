import logo from '../assets/logo.png'

const Team = ({ team }) => {
    return (
        <section className="team">
            <div className="section-title">
                <h2>
                    get to know <span> our team </span>
                </h2>
            </div>

            <div className="team-container">
                { team.map((member) => (
                    <div key={ member.id } className="member">
                        <div className="member-image">
                            { member.img ? <img src={ member.img } alt={ member.name } /> : < img src={ logo } alt={ member.name } /> }

                        </div>

                        <div className="member-name">
                            <h2>
                                { member.name }
                            </h2>
                        </div>
                        <div className="member-role">
                            <p>{ member.role }</p>
                        </div>

                        <ul className="member-socials">
                            { member.facebook &&
                                <li>
                                    <a href={ member.facebook }> <i class="fa-brands fa-square-facebook"></i>  </a>
                                </li>
                            }
                            { member.instagram &&
                                <li>
                                    <a href={ member.instagram }> <i class="fa-brands fa-instagram"></i>  </a>
                                </li>
                            }
                            { member.twitter &&
                                <li>
                                    <a href={ member.twitter }> <i class="fa-brands fa-twitter"></i>   </a>
                                </li>
                            }
                            { member.linkedin &&
                                <li>
                                    <a href={ member.linkedin }> <i class="fa-brands fa-linkedin"></i>  </a>
                                </li>
                            }
                            { member.behance &&
                                <li>
                                    <a href={ member.behance }> <i class="fa-brands fa-behance"></i>  </a>
                                </li>
                            }
                            { member.github &&
                                <li>
                                    <a href={ member.github }> <i class="fa-brands fa-github"></i>  </a>
                                </li>
                            }
                            { member.website &&
                                <li>
                                    <a href={ member.website }> <i class="fa-solid fa-earth-africa"></i>  </a>
                                </li>
                            }
                        </ul>
                    </div>
                )) }
            </div>
        </section>
    )
}

export default Team;