import ProjectsView from "./components/projects-view";
import useFetch from "./Hooks/useFetch";

const Projects = () => {
    const { data: projects } = useFetch("projects");

    return (
        <>
            { projects && < ProjectsView projects={ projects } /> }
        </>
    );
}

export default Projects;