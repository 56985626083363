const Footer = () => {
    return (
        <footer className="page-footer">
            <div className="footer-container">
                <p className="name">
                    up 2 production
                </p>

                <ul className="social-media">
                    <li>
                        <a href="https://www.facebook.com/profile.php?id=100087044603719">
                            <i className="fa-brands fa-square-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.behance.net/up2production">
                            <i className="fa-brands fa-behance"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/Up2Production">
                            <i className="fa-brands fa-twitter"></i>
                        </a>
                    </li>
        <li>
                        <a href="https://www.instagram.com/up2.production/">
                               <i className="fa-brands fa-instagram"></i>
                        </a>
                    </li>
     
                </ul>

                <p className="email">
                    contact@up2production.com
                </p>
            </div>
        </footer>
    );
}

export default Footer;
