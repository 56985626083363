import Banner from "./components/Banner";
import Services from "./components/Services";
import useFetch from "./Hooks/useFetch";
import ProjectsView from "./components/projects-view";
import Collabs from "./components/collaborations";

const Home = () => {
    const { data: services, error, isPending } = useFetch("services");
    const { data: topprojects } = useFetch("projects?tag=top");
    const { data: collaborations } = useFetch("collaborations");

    return (
        <>
            <Banner />
            <div className="ribbon ribbon-banner"></div>

            { error && <div>{ error }</div> }

            { isPending && <div>Loading...</div> }

            { topprojects && <ProjectsView projects={ topprojects } button={ true } /> }
            <div className="ribbon"></div>

            { services && <Services services={ services } type="icons" /> }
            <div className="ribbon"></div>

            { collaborations && <Collabs collabs={ collaborations } /> }
            <div className="ribbon"></div>
        </>
    );
}

export default Home;