const Services = ({ services, type }) => {
    return (
        <section className="services">
            <div className="layer">
                <div className="section-title">
                    <h2>
                        services  we <span> provide </span>
                    </h2>


                </div>

                <div className={ `services-container type-${type}` }>
                    {
                        services.map((service) => (
                            type === "icons" ?
                                <div key={ service.id } className="service">
                                    <div className="service-icon">
                                        <i className={ service.icon }></i>
                                    </div>

                                    <div className="service-title">
                                        <h2>
                                            { service.title }
                                        </h2>
                                    </div>

                                    <div className="service-description">
                                        <p>
                                            { service.description }
                                        </p>
                                    </div>
                                </div>
                                : <div className="service"> { service.title } </div>
                        ))

                    }

                </div>
            </div>
        </section >
    );
}

export default Services;